import React, { useEffect, useState } from "react";
import { InputAdornment, IconButton } from "@mui/material";

import { Label, StyledTextField, Container } from "./styles";
import {
  ErrorIcon,
  HidePasswordIcon,
  SearchIcon,
  ShowPasswordIcon,
} from "../../assets";
import theme from "../../definitions/theme";

export interface InputProps {
  id?: string;
  placeholder?: string;
  value?: string;
  setValue?: React.Dispatch<React.SetStateAction<string>>;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  disabled?: boolean;
  error?: boolean;
  width?: number | string;
  maxWidth?: number | string;
  label?: string;
  type?: string;
  errorLabel?: string;
  tagsOptions?: Array<string>;
  trash?: boolean;
  onIconClick?: () => void;
  helperText?: string;
  password?: boolean;
  showPassword?: boolean;
  className?: string;
  showErrorLabel?: boolean;
  multiline?: boolean;
  fontFamily?: string;
  preview?: boolean;
  onFocus?: () => void;
  icon?: any;
  budget?: boolean;
  initialValue?: string;
}

const TextInput: React.FC<InputProps> = ({
  id,
  placeholder = "",
  value,
  initialValue,
  onChange,
  onBlur,
  disabled = false,
  error = false,
  errorLabel,
  width,
  maxWidth,
  label,
  type,
  onIconClick,
  helperText = "",
  password,
  showPassword,
  className,
  showErrorLabel = true,
  multiline = false,
  fontFamily,
  preview = false,
  onFocus,
  icon,
  budget = false,
}) => {
  const [currentValue, setValue] = useState(value || initialValue);

  useEffect(() => {
    setValue(value || initialValue);
  }, [value, initialValue]);

  const handleChange = (e: any) => {
    setValue(e.target.value);
    onChange?.(e);
  };
  return (
    <Container className={className}>
      {label && <Label>{label}</Label>}
      <StyledTextField
        id={id}
        multiline={multiline}
        minRows={7}
        maxRows={7}
        className={className}
        placeholder={placeholder}
        disabled={disabled}
        type={type}
        value={currentValue}
        error={error}
        helperText={helperText}
        onChange={handleChange}
        fontFamily={fontFamily}
        onBlur={onBlur}
        style={{ width: width || "100%", maxWidth: maxWidth || "inherit" }}
        preview={preview}
        onFocus={onFocus}
        budget={budget}
        icon={!!icon}
        InputProps={{
          startAdornment: (
            <>
              {icon && <SearchIcon style={{ margin: "8px" }} />}
              {budget && <Label budget>$</Label>}
            </>
          ),
          endAdornment: (
            <>
              {!password && error && (
                <InputAdornment position="end" disablePointerEvents>
                  <IconButton>
                    <ErrorIcon />
                  </IconButton>
                </InputAdornment>
              )}
              {password && (
                <InputAdornment onClick={onIconClick} position="end">
                  <IconButton>
                    {showPassword ? <ShowPasswordIcon /> : <HidePasswordIcon />}
                  </IconButton>
                </InputAdornment>
              )}
            </>
          ),
        }}
        FormHelperTextProps={{
          style: {
            marginLeft: 0,
            color: theme.customPalette.mediumGrey,
            marginTop: 0,
            cursor: "auto",
            fontSize: "auto",
          },
        }}
      />
      {showErrorLabel && <Label error>{error ? errorLabel : ""}</Label>}
    </Container>
  );
};

export default TextInput;
