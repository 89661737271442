/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "redux/store";

import Header from "./header";
import PageWrapper from "components/pageWrapper";
import {
  ButtonContainer,
  Container,
  IconContainer,
  InputAdornment,
  Line,
  ResponseContainer,
  ResponseTitle,
  ResponseTitleContainer,
  ResponsesContainer,
} from "./styles";
import { CANCEL, SAVE_EDITION, SURVEY_ANSWERS } from "utils/constants";
import { BlankPencilIcon } from "assets";
import QuestionResponseCard from "components/questionResponseCard";
import {
  convertGroupedToAdminResponses,
  groupQuestionResponses,
  isEditable,
} from "utils/utils";
import { Button, ErrorModal } from "components";
import { clearApiError } from "redux/slices/userResponseSlice";
import useResponseLogic from "../../hooks/useResponseLogic";
import { generateToken } from "pages/Responses/services/generateToken";

const ResponseDetails = () => {
  const dispatch = useAppDispatch();
  const [isEditing, setIsEditing] = useState(false);
  const [userLink, setUserLink] = useState("");
  const { clientURL } = useAppSelector((state) => state.authSlice);

  const { userResponses, apiError } = useAppSelector(
    (state) => state.userResponseSlice
  );
  const { id } = useParams();
  const userResponse = userResponses.find(
    (response) => response.id === Number(id)
  );
  const { name, surname, company } = userResponse.user;
  const { questionResponses } = userResponse;

  
  const groupedResponses = groupQuestionResponses(questionResponses);
  
  const generateUserLink = async () => {
    const token = await generateToken(Number(id));
    const link = `${
      clientURL || process.env.REACT_APP_BASE_URL
    }?token=${token}`;
    setUserLink(link);
  };

  useEffect(() => {
    generateUserLink();
  }, []);

  const { onChangeText, onChangeQuestionOption, handleSaveChanges } =
    useResponseLogic({
      userResponseId: Number(id),
      responses: convertGroupedToAdminResponses(groupedResponses),
    });

  const canEdit = useCallback(
    () =>
      groupedResponses.some((questionResponses) =>
        questionResponses.some((response) =>
          isEditable(response.questionResponses)
        )
      ),
    [groupedResponses]
  );

  return (
    <PageWrapper>
      <Container>
        <Header
          responseId={Number(id)}
          name={name}
          lastname={surname}
          company={company}
          pdfUrl={userResponse.pdfUrl}
          linkForUser={userLink}
          maturityCategory={userResponse?.maturityCategory}
          temperatureCategory={userResponse?.temperatureCategory}
          techsTags={userResponse?.techsTags}
        />
        <Line />
        <ResponseContainer>
          <ResponseTitleContainer>
            <ResponseTitle>{SURVEY_ANSWERS}</ResponseTitle>
            {isEditing ? (
              <ButtonContainer>
                <Button
                  label={CANCEL}
                  variantType="secondary"
                  width={140}
                  height={40}
                  onClick={() => setIsEditing(false)}
                />
                <Button
                  label={SAVE_EDITION}
                  variantType="primary"
                  width={140}
                  height={40}
                  onClick={() => {
                    handleSaveChanges();
                    setIsEditing(false);
                  }}
                />
              </ButtonContainer>
            ) : canEdit() ? (
              <IconContainer>
                <InputAdornment onClick={() => setIsEditing(true)}>
                  <BlankPencilIcon />
                </InputAdornment>
              </IconContainer>
            ) : (
              <></>
            )}
          </ResponseTitleContainer>
          <ResponsesContainer>
            {groupedResponses.map((group, index) => (
              <QuestionResponseCard
                key={index}
                onChangeText={onChangeText}
                questionGroupTitle={group[0].question.questionGroup.title}
                onChangeQuestionOption={onChangeQuestionOption}
                questionResponses={group}
                isEditing={isEditing}
              />
            ))}
          </ResponsesContainer>
        </ResponseContainer>
      </Container>
      {apiError && (
        <ErrorModal isOpen handleClose={dispatch(clearApiError())} />
      )}
    </PageWrapper>
  );
};

export default ResponseDetails;
