import { useState } from "react";

interface Props {
  initialTag: string | string[];
}

const useTagLogic = ({ initialTag }: Props) => {
  const [selectedTag, setSelectedTag] = useState<string | string[]>(
    typeof initialTag === "string"
      ? initialTag?.toLowerCase()
      : initialTag?.map((tag) => tag.toLowerCase())
  );

  const handleTagClick = (value: string) =>
    typeof initialTag === "string"
      ? setSelectedTag((prevValue) => (prevValue === value ? "" : value))
      : setSelectedTag((prevTags) => {
          if (prevTags.includes(value))
            return (prevTags as string[]).filter((tag) => tag !== value);
          else return [...(prevTags as string[]), value];
        });

  return { handleTagClick, selectedTag, setSelectedTag };
};

export default useTagLogic;
