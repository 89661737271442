import React, { useEffect, useState } from "react";
import { Collapse } from "@mui/material";

import {
  Container,
  Content,
  ExpandMoreButton,
  Title,
  RowContainer,
  AdminLabel,
  TitleContainer,
} from "./styles";
import { ExpandArrow } from "assets";
import { GroupedQuestionResponses } from "types";
import ResponseText from "components/responseText";
import QuestionResponseMatcherComponent from "components/responseMatcherComponent";
import { isAdminAnswer, isEditable } from "../../utils/utils";
import { EDITED_BY_ADMIN } from "utils/constants";
import { getTechStack } from "pages/ResponseDetails/services/getTechStack";

interface QuestionResponseCardProps {
  questionResponses: GroupedQuestionResponses[];
  questionGroupTitle: string;
  isEditing?: boolean;
  onChangeText: (
    event: React.ChangeEvent<HTMLInputElement> | string,
    questionId: number
  ) => void;
  onChangeQuestionOption: (
    questionId: number,
    questionType: string,
    optionText?: string,
    optionId?: number
  ) => void;
}

const QuestionResponseCard: React.FC<QuestionResponseCardProps> = ({
  onChangeText,
  questionResponses,
  isEditing,
  onChangeQuestionOption,
  questionGroupTitle,
}) => {
  const [expanded, setExpanded] = useState(true);
  const [techStack, setTechStack] = useState<{
    primary: string[];
    secondary: [];
  }>({ primary: [], secondary: [] });

  const handleExpandeClick = () => setExpanded(!expanded);

  const getStackTechnologies = async () => {
    const techStack = await getTechStack();
    setTechStack(techStack);
  };

  useEffect(() => {
    getStackTechnologies();
  }, []);

  return (
    <Container expand={expanded}>
      <RowContainer expand={expanded}>
        <Title questionTitle={false}>{questionGroupTitle}</Title>
        <ExpandMoreButton expand={expanded} onClick={handleExpandeClick}>
          <ExpandArrow />
        </ExpandMoreButton>
      </RowContainer>
      {questionResponses.map((response) => (
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <Content>
            <TitleContainer>
              <Title questionTitle>
                {response.question.questionText}
                {response.question.required ? " *" : ""}
              </Title>
              {isAdminAnswer(response.questionResponses) && (
                <AdminLabel>{EDITED_BY_ADMIN}</AdminLabel>
              )}
            </TitleContainer>
            {isEditing && isEditable(response.questionResponses) ? (
              <QuestionResponseMatcherComponent
                questionResponses={response.questionResponses}
                onChangeText={onChangeText}
                onChangeQuestionOption={onChangeQuestionOption}
                techStack={techStack}
              />
            ) : (
              <ResponseText
                responses={response.questionResponses}
                techStack={techStack}
              />
            )}
          </Content>
        </Collapse>
      ))}
    </Container>
  );
};

export default QuestionResponseCard;
