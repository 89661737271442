import { styled, TextField } from "@mui/material";

import theme from "../../definitions/theme";

const getTextColor = (
  disabled: boolean | undefined,
  error: boolean | undefined
) => {
  return disabled
    ? theme.customPalette.textField.disabled.text
    : error
    ? theme.customPalette.textField.error.text
    : theme.customPalette.textField.default.text;
};

const getBorderColor = (
  disabled: boolean | undefined,
  error: boolean | undefined
) => {
  return disabled
    ? theme.customPalette.textField.disabled.border
    : error
    ? theme.customPalette.textField.error.border
    : theme.customPalette.textField.default.border;
};

const getBorderHoverColor = (
  disabled: boolean | undefined,
  error: boolean | undefined
) => {
  return disabled
    ? theme.customPalette.textField.disabled.border
    : error
    ? theme.customPalette.textField.error.border
    : theme.customPalette.textField.hover.border;
};

interface InputProps {
  id?: string;
  placeholder: string;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  onKeyDown?: (e: React.KeyboardEvent<HTMLDivElement>) => void;
  disabled?: boolean;
  error?: boolean;
  width?: number | string;
  label?: string;
  type?: string;
  fontFamily?: string;
  preview?: boolean;
  icon?: boolean;
  budget?: boolean;
}

export const StyledTextField = styled(TextField)<InputProps>(
  ({ disabled, error, fontFamily, preview, icon, budget }) => {
    return {
      cursor: disabled ? "initial" : "pointer",
      height: "48px",
      width: "355px",
      zIndex: preview ? -1 : "auto",

      ".MuiInputBase-root": {
        padding: "0",
        fontFamily: fontFamily || theme.customFonts.poppinsLight,
      },

      ".MuiOutlinedInput-root": {
        height: "48px !important",
        "& fieldset": {
          borderRadius: "7px",
          border: `2px solid ${getBorderColor(disabled, error)}`,
        },
        "&.Mui-focused fieldset": {
          border: `2px solid ${getBorderHoverColor(disabled, error)} `,
        },
      },

      ".MuiInputBase-input": {
        fontSize: theme.customSizes.textField.text.fontSize,
        fontWeight: theme.customSizes.textField.text.fontWeight,
        lineHeight: theme.customSizes.textField.text.lineHeight,
        color: getTextColor(disabled, error),
        padding: !icon && !budget && "16px",

        "&::placeholder": {
          color: theme.customPalette.darkMediumGrey,
          fontSize: theme.customSizes.textField.placeholder.fontSize,
          fontWeight: theme.customSizes.textField.placeholder.fontWeight,
          lineHeight: theme.customSizes.textField.placeholder.lineHeight,
          fontFamily:
            fontFamily || theme.customSizes.textField.placeholder.fontFamily,
        },
      },

      "&.MuiOutlinedInput-notchedOutline": {
        borderRadius: "7px",
        border: `2px solid ${getBorderColor(disabled, error)} !important`,
      },

      ":hover": {
        ".MuiOutlinedInput-notchedOutline": {
          border: `2px solid ${getBorderHoverColor(
            disabled,
            error
          )} !important`,
        },
        color: `${theme.customPalette.textAreaInput.activeText}`,
      },

      ".Mui-focused": {
        ".MuiOutlinedInput-notchedOutline": {
          border: `2px solid
        ${
          disabled
            ? theme.customPalette.textField.disabled.border
            : theme.customPalette.textField.active.border
        } !important`,
        },
        ".MuiInputBase-input": {
          color: `${theme.customPalette.textField.active.text}`,
        },
        color: `${theme.customPalette.textField.active.text}`,
        border: `${
          disabled
            ? theme.customPalette.textField.disabled.border
            : theme.customPalette.textField.active.border
        }`,
      },

      ".Mui-error": {
        ".MuiOutlinedInput-notchedOutline": {
          border: `2px solid ${theme.customPalette.textField.error.border} !important`,
        },
        ".MuiInputBase-input": {
          color: `${theme.customPalette.textField.error.text}`,
        },
      },

      ".Mui-disabled": {
        ".MuiOutlinedInput-notchedOutline": {
          border: `2px solid ${theme.customPalette.textField.disabled.border} !important`,
        },
        ".MuiInputBase-input": {
          color: `${theme.customPalette.textField.disabled.text}`,
        },
      },

      "&.simple": {
        "& .MuiInputBase-input": {
          width: "100%",
          padding: 0,
          paddingLeft: "14px",
          fontWeight: 600,
          fontSize: "15px",
          color: theme.customPalette.primary,
          fontFamily: fontFamily || theme.customFonts.poppinsSemiBold,
        },
        "& .MuiOutlinedInput-root": {
          padding: 0,
          height: "45px !important",
          "& fieldset": {
            border: "none",
          },
        },
      },

      "&.tag": {
        "& .MuiInputBase-input": {
          padding: 0,
          paddingLeft: "14px",
          fontWeight: 400,
          fontSize: "15px",
          color: theme.customPalette.primary,
          fontFamily: fontFamily || theme.customFonts.poppinsSemiBold,
        },
        "& .MuiOutlinedInput-root": {
          width: "680px",
          padding: 0,
          height: "45px !important",
        },
      },

      "&.subtitle": {
        "& .MuiInputBase-input": {
          padding: 0,
          paddingLeft: "14px",
          fontSize: "12px",
          fontWeight: 400,
          lineHeight: "24px",
          color: theme.customPalette.darkMediumGrey,
          fontFamily: fontFamily || theme.customFonts.poppins,
          opacity: "60%",
          marginTop: 0,
        },
        "& .MuiOutlinedInput-root": {
          padding: 0,
          height: "30px !important",
          "& fieldset": {
            border: "none",
          },
        },
      },

      "&.multiline": {
        height: "unset !important",
        "& .MuiInputBase-input": {
          padding: "16px",
        },
        "& .MuiOutlinedInput-root": {
          height: "unset !important",
          width: "680px",
        },
      },
      "& input:-webkit-autofill": {
        "-webkit-box-shadow": "0 0 0 30px white inset !important",
      },
    };
  }
);

export const Container = styled("div")`
  display: flex;
  flex-direction: column;
  height: fit-content;
  gap: 4px;
  width: 100%;
  &.subtitle {
    margin-left: 48px;
    flex-grow: 1;
    width: unset;
  }
  &.noWidth {
    width: unset;
  }
`;

interface LabelProps {
  error?: boolean;
  budget?: boolean;
}

export const Label = styled("p")<LabelProps>`
  font-family: ${theme.typography.fontFamily};
  font-size: ${theme.customSizes.textField.label.fontSize};
  font-weight: ${theme.customSizes.textField.label.fontWeight};
  line-height: ${theme.customSizes.textField.label.lineHeight};
  color: ${({ error }) =>
    error ? theme.customPalette.error : theme.customPalette.darkMediumGrey};
  height: 20px;
  padding: ${({ budget }) => (budget ? "0 8px 0 16px" : "0 0 0 3px")};
  margin: 0;
`;
