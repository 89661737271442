import React from "react";
import { menuClasses } from "@mui/material/Menu";
import { selectClasses } from "@mui/material/Select";
import { Select } from "@mui/material";

import CheckoutButton from "../../components/checkbox";
import theme from "../../definitions/theme";
import { ExpandMore } from "../../assets";
import {
  maturityTagsOptions,
  techTagsOptions,
  temperatureTagsOptions,
} from "../../utils/utils";
import {
  Description,
  MenuItemStyled,
  SelectInput,
  TagsDivider,
} from "./styles";
import { MATURITY, TECH, TEMPERATURE } from "../../utils/constants";
import CategoryCard from "../../components/categoryCard";

export interface Props {
  selectedMaturityTag?: string;
  selectedTemperatureTag?: string;
  selectedTechTags?: string[];
  handleMaturityTagClick?: (value: string) => void;
  handleTemperatureTagClick?: (value: string) => void;
  handleTechTagClick?: (value: string) => void;
}

const StyledSelect = ({
  selectedMaturityTag,
  selectedTemperatureTag,
  selectedTechTags = [],
  handleMaturityTagClick,
  handleTemperatureTagClick,
  handleTechTagClick,
}: Props) => {
  const selectedTags = [
    selectedMaturityTag,
    selectedTemperatureTag,
    ...selectedTechTags,
  ];

  return (
    <Select
      disableUnderline
      variant="standard"
      multiple
      MenuProps={{
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "left",
        },
        transformOrigin: {
          vertical: "top",
          horizontal: "left",
        },
        sx: {
          alignItems: "center",
          marginTop: "6px",
          maxHeight: "500px",
          [`& .${menuClasses.paper}`]: {
            borderRadius: "7px",
            border: `2px solid ${theme.customPalette.secondary}`,
          },
          [`& .${menuClasses.list}`]: {
            paddingTop: 0,
            paddingBottom: 0,
            background: "white",
            marginTop: "10px",
            "& li:hover": {
              backgroundColor: "transparent !important",
            },
            "& li.Mui-selected": {
              color: "white",
              backgroundColor: "transparent !important",
            },
            "& li.Mui-selected:hover": {
              backgroundColor: "transparent !important",
            },
          },
        },
      }}
      value={selectedTags}
      renderValue={() => (
        <SelectInput>
          <CategoryCard text={selectedMaturityTag} />
          <CategoryCard text={selectedTemperatureTag} />
          {selectedTechTags?.length > 0 &&
            selectedTechTags?.map((techTag) => <CategoryCard text={techTag} />)}
        </SelectInput>
      )}
      displayEmpty
      IconComponent={ExpandMore}
      sx={{
        minWidth: 450,
        maxWidth: 450,
        [`& .${selectClasses.select}`]: {
          background: "white",
          display: "flex",
          color: theme.customPalette.grey,
          borderRadius: "7px",
          border: `2px solid ${theme.customPalette.textField.default.border}`,
          paddingLeft: "12px",
          paddingRight: "18px",
          paddingTop: "10px",
          paddingBottom: "10px",
          alignItems: "center",
          "&:focus": {
            borderRadius: "7px",
            border: `2px solid ${theme.customPalette.textField.default.border}`,
          },
          "&:hover": {
            borderRadius: "7px",
            border: `2px solid ${theme.customPalette.hover}`,
          },
        },
        [`& .${selectClasses.icon}`]: {
          right: "2px",
          top: "12px",
        },
      }}
    >
      {" "}
      <Description>{MATURITY}:</Description>
      {maturityTagsOptions.map((label) => (
        <MenuItemStyled key={label} value={label} disableRipple>
          <CheckoutButton
            labelValue={label}
            focus={selectedTags.includes(label)}
            onClick={() => handleMaturityTagClick(label)}
            tag
          />
        </MenuItemStyled>
      ))}
      <TagsDivider />
      <Description>{TEMPERATURE}:</Description>
      {temperatureTagsOptions.map((label) => (
        <MenuItemStyled key={label} value={label} disableRipple>
          <CheckoutButton
            labelValue={label}
            focus={selectedTags.includes(label)}
            onClick={() => handleTemperatureTagClick(label)}
            tag
          />
        </MenuItemStyled>
      ))}
      <TagsDivider />
      <Description>{TECH}:</Description>
      {techTagsOptions.map((label) => (
        <MenuItemStyled key={label} value={label} disableRipple>
          <CheckoutButton
            labelValue={label}
            focus={selectedTags.includes(label)}
            onClick={() => handleTechTagClick(label)}
            tag
          />
        </MenuItemStyled>
      ))}
    </Select>
  );
};

export default StyledSelect;
