import React, { useState } from "react";
import { IconButton } from "@mui/material";
import { useNavigate } from "react-router-dom";

import {
  ButtonContainer,
  HeaderContainer,
  HeaderTextContainer,
  HeaderUserContainer,
  IconContainer,
  InputAdornment,
  LeftContainer,
  RightContainer,
  StyledCopyPasteIcon,
  TagsContainer,
  Text,
  TextContainer,
  Title,
  UserInfoContainer,
} from "./styles";
import { ArrowRight, BackArrow, BlankPencilIcon } from "assets";
import {
  ANSWERS,
  CANCEL,
  CLIENT_INFORMATION,
  COMPANY,
  DOWNLOAD,
  LASTNAME,
  LINK_FOR_USER,
  NAME,
  RESPONSES,
  SAVE_EDITION,
  SELECT_TAGS,
  USER_RESPONSES,
} from "utils/constants";
import { Button } from "components";
import CopiedToClipboardModal from "components/copiedModal";
import StyledSelect from "./select";
import useResponseTagsLogic from "../../hooks/useResponseTagsLogic";
import CategoryCard from "../../components/categoryCard";
import { useAppSelector } from "../../redux/store";

interface Props {
  responseId: number;
  name: string;
  lastname: string;
  company: string;
  pdfUrl: string;
  linkForUser: string;
  maturityCategory?: string;
  temperatureCategory?: string;
  techsTags?: string[];
}

const Header = ({
  responseId,
  name,
  lastname,
  company,
  pdfUrl,
  linkForUser,
  maturityCategory,
  temperatureCategory,
  techsTags = [],
}: Props) => {
  const navigate = useNavigate();
  const { isAIActive } = useAppSelector((state) => state.companySlice);
  const [isCopyLink, setIsCopyLink] = useState(false);

  const {
    isEditing,
    setIsEditing,
    selectedMaturityTag,
    selectedTechTags,
    selectedTemperatureTag,
    handleCancel,
    handleMaturityTagClick,
    handleTechTagClick,
    handleTemperatureTagClick,
    handleSaveChanges,
    noTagsSelected,
  } = useResponseTagsLogic({
    responseId,
    maturityCategory,
    temperatureCategory,
    techsTags,
  });

  const downloadResponsesPDF = () => {
    if (pdfUrl)
      fetch(pdfUrl).then((response) => {
        response.blob().then((blob) => {
          const fileURL = window.URL.createObjectURL(blob);
          let link = document.createElement("a");
          link.href = fileURL;
          link.download = `${company} - ${USER_RESPONSES}`;
          link.click();
        });
      });
  };

  const handleCopyLink = () => {
    navigator.clipboard.writeText(linkForUser);
    setIsCopyLink(true);
    setTimeout(() => {
      setIsCopyLink(false);
    }, 2000);
  };

  return (
    <HeaderContainer>
      <LeftContainer>
        <HeaderTextContainer>
          <InputAdornment>
            <IconButton onClick={() => navigate("/responses")}>
              <BackArrow />
            </IconButton>
          </InputAdornment>
          <Text>{RESPONSES}</Text>
          <InputAdornment>
            <ArrowRight />
          </InputAdornment>
          <Text className="bold">{ANSWERS}</Text>
        </HeaderTextContainer>
        <HeaderUserContainer>
          <Title>{CLIENT_INFORMATION}</Title>
          <UserInfoContainer>
            <TextContainer>
              <Text className="bold">{NAME}:</Text>
              <Text>{name}</Text>
            </TextContainer>
            <TextContainer>
              <Text className="bold">{LASTNAME}</Text>
              <Text>{lastname}</Text>
            </TextContainer>
            <TextContainer>
              <Text className="bold">{COMPANY}</Text>
              <Text>{company}</Text>
            </TextContainer>
          </UserInfoContainer>
          {isAIActive && (
            <TagsContainer>
              {isEditing ? (
                <StyledSelect
                  selectedTemperatureTag={selectedTemperatureTag}
                  handleTemperatureTagClick={handleTemperatureTagClick}
                  selectedMaturityTag={selectedMaturityTag}
                  handleMaturityTagClick={handleMaturityTagClick}
                  selectedTechTags={selectedTechTags}
                  handleTechTagClick={handleTechTagClick}
                />
              ) : (
                <>
                  {noTagsSelected && <Text>{SELECT_TAGS}</Text>}
                  <CategoryCard text={selectedMaturityTag} />
                  <CategoryCard text={selectedTemperatureTag} />
                  {selectedTechTags?.length > 0 &&
                    selectedTechTags?.map((techTag) => (
                      <CategoryCard text={techTag} />
                    ))}
                </>
              )}
              {isEditing ? (
                <ButtonContainer>
                  <Button
                    label={CANCEL}
                    variantType="secondary"
                    width={140}
                    height={40}
                    onClick={handleCancel}
                  />
                  <Button
                    label={SAVE_EDITION}
                    variantType="primary"
                    width={140}
                    height={40}
                    onClick={handleSaveChanges}
                  />
                </ButtonContainer>
              ) : (
                <IconContainer>
                  <InputAdornment onClick={() => setIsEditing(true)}>
                    <BlankPencilIcon />
                  </InputAdornment>
                </IconContainer>
              )}
            </TagsContainer>
          )}
          <UserInfoContainer>
            <TextContainer>
              <Text className="bold">{LINK_FOR_USER}</Text>
              <StyledCopyPasteIcon onClick={handleCopyLink} />
            </TextContainer>
          </UserInfoContainer>
        </HeaderUserContainer>
      </LeftContainer>
      <RightContainer>
        <Button
          label={DOWNLOAD}
          variantType="primary"
          width={227}
          height={48}
          onClick={downloadResponsesPDF}
          disabled={!pdfUrl}
        />
      </RightContainer>
      {isCopyLink && <CopiedToClipboardModal />}
    </HeaderContainer>
  );
};

export default Header;
