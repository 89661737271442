import { ChangeEvent, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDebounce } from "use-debounce";

import {
  HeaderContainer,
  InputAdornment,
  HeaderTextContainer,
  Text,
  IconButtonDiv,
} from "./styles";
import { SURVEY_DETAILS } from "../../utils/constants";
import { ArrowRight, BackArrow } from "../../assets";
import { TextInput } from "../../components";
import { editSurvey } from "../Survey/services/editSurvey";

interface Props {
  name: string;
  surveyId: number;
}

const Header = ({ name, surveyId }: Props) => {
  const navigate = useNavigate();
  const [nameToEdit, setNameToEdit] = useState(name);
  const [debouncedName] = useDebounce(nameToEdit, 1000);

  const editSurveyCall = async () => editSurvey(surveyId, { name: nameToEdit });

  useEffect(() => {
    if (debouncedName !== name) editSurveyCall();
  }, [debouncedName]);

  return (
    <HeaderContainer>
      <HeaderTextContainer>
        <InputAdornment>
          <IconButtonDiv onClick={() => navigate("/survey")}>
            <BackArrow />
          </IconButtonDiv>
        </InputAdornment>
        <Text>{SURVEY_DETAILS}</Text>
        <InputAdornment>
          <ArrowRight />
        </InputAdornment>
        <TextInput
          onChange={(event: ChangeEvent<HTMLInputElement>) =>
            setNameToEdit(event.target.value)
          }
          value={nameToEdit}
          className="simple"
          showErrorLabel={false}
        />
      </HeaderTextContainer>
    </HeaderContainer>
  );
};

export default Header;
