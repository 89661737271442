import React, { useState } from "react";
import dayjs from "dayjs";
import { useMediaQuery } from "@mui/material";
import { useNavigate } from "react-router-dom";

import PageWrapper from "../../components/pageWrapper";
import {
  ButtonsCell,
  Container,
  DefaultContainer,
  EmptyDiv,
  IconButton,
  TableCell,
  TableHead,
  TableRow,
  Text,
  TextContainer,
  Title,
} from "./styles";
import { CopyIcon, DotsIcon, TrashIcon } from "../../assets";
import {
  CREATE_SURVEY,
  DATE_FORMAT,
  DELETE_SURVEY,
  DELETE_SURVEY_CONFIRMATION_MESSAGE,
  DUPLICATE,
  NEW_SURVEY_NAME,
  PATH_RULES,
  QUESTIONS,
  SET_AS_DEFAULT,
  SURVEY,
  SURVEY_DELETION_WARNING_MESSAGE,
  UPDATED,
} from "../../utils/constants";
import theme from "../../definitions/theme";
import { Button, ErrorModal, Modal } from "../../components";
import { createSurvey } from "./services/createSurvey";
import useSurveyLogic from "../../hooks/useSurveyLogic";
import Tooltip from "components/tooltip";

const Survey = () => {
  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.down(760));
  const [isTooltipModalOpen, setIsTooltipModalOpen] = useState(false);
  const [tooltipText, setTooltipText] = useState("");
  const [mousePosition, setMousePosition] = useState({ left: 0, top: 0 });
  const {
    surveys,
    surveyToDelete,
    showModal,
    apiError,
    setShowModal,
    handleDefaultClick,
    handleDeleteClick,
    handleDeleteSurvey,
    handleCloseErrorModal,
    handleTryAgainErrorModal,
    handlePathRuleClick,
    surveyQuestionsAmount,
    handleCloneSurvey,
  } = useSurveyLogic();

  const handleMouseOver = (
    event: React.MouseEvent<HTMLElement>,
    icon: string,
    isDefault?: boolean
  ) => {
    event.stopPropagation();
    const mouseX = event.clientX;
    const mouseY = event.clientY;
    setMousePosition({ left: mouseX, top: mouseY });

    setIsTooltipModalOpen(true);
    switch (icon) {
      case "default":
        if (!isDefault) setTooltipText(SET_AS_DEFAULT);
        else setIsTooltipModalOpen(false);
        break;
      case "delete":
        setTooltipText(DELETE_SURVEY);
        break;
      case "pathRule":
        setTooltipText(PATH_RULES);
        break;
      case "duplicate":
        setTooltipText(DUPLICATE);
        break;
    }
  };

  const handleMouseLeave = () => {
    setIsTooltipModalOpen(false);
  };

  return (
    <PageWrapper>
      <Container>
        <TextContainer>
          <Title>{SURVEY}</Title>
          <Button
            label={CREATE_SURVEY}
            variantType="primary"
            width={180}
            height={48}
            onClick={async () => await createSurvey({ name: NEW_SURVEY_NAME })}
          />
        </TextContainer>
        <TableHead>
          <TableCell className="bold start-position">{SURVEY}</TableCell>
          <TableCell className="bold">{QUESTIONS}</TableCell>
          <TableCell className="bold">{UPDATED}</TableCell>
          <EmptyDiv />
        </TableHead>
        {surveys?.map(({ name, id, updated_at, questionGroups, isDefault }) => (
          <TableRow onClick={() => navigate(`/survey/${id}`)}>
            <DefaultContainer
              isDefault={isDefault}
              onMouseOver={(e) => handleMouseOver(e, "default", isDefault)}
              onMouseLeave={handleMouseLeave}
              onClick={(event) => handleDefaultClick(event, id)}
            />
            <TableCell className="start-position">
              <Text>{name}</Text>
            </TableCell>
            <TableCell className="pl">{`${surveyQuestionsAmount(
              questionGroups
            )} ${QUESTIONS.toLocaleLowerCase()}`}</TableCell>
            <TableCell className="pl grey">
              {`${isMobile ? "Updated " : ""} ${dayjs(updated_at).format(
                DATE_FORMAT
              )}`}
            </TableCell>
            <ButtonsCell>
              <IconButton
                className="stroke"
                onClick={(event) => handlePathRuleClick(event, id)}
                onMouseOver={(e) => handleMouseOver(e, "pathRule")}
                onMouseLeave={handleMouseLeave}
              >
                <DotsIcon />
              </IconButton>
              <IconButton
                className="ml stroke"
                onClick={(event) => handleCloneSurvey(event, id)}
                onMouseOver={(e) => handleMouseOver(e, "duplicate")}
                onMouseLeave={handleMouseLeave}
              >
                <CopyIcon />
              </IconButton>
              <IconButton
                className="fill"
                onClick={(event) => handleDeleteClick(event, { name, id })}
                onMouseOver={(e) => handleMouseOver(e, "delete")}
                onMouseLeave={handleMouseLeave}
              >
                <TrashIcon />
              </IconButton>
              {isTooltipModalOpen && (
                <Tooltip text={tooltipText} position={mousePosition} />
              )}
            </ButtonsCell>
          </TableRow>
        ))}
      </Container>
      {apiError && (
        <ErrorModal
          isOpen
          handleClose={handleCloseErrorModal}
          handleTryAgain={handleTryAgainErrorModal}
        />
      )}
      <Modal
        isOpen={showModal}
        handleClose={() => setShowModal(false)}
        handleDelete={handleDeleteSurvey}
        message={`${DELETE_SURVEY_CONFIRMATION_MESSAGE} ${surveyToDelete?.name}.`}
        description={SURVEY_DELETION_WARNING_MESSAGE}
      />
    </PageWrapper>
  );
};

export default Survey;
