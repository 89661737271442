import React from "react";

import { Text, TagsContainer } from "./styles";
import { getResponse, QuestionType, splitResponse } from "../../utils/utils";
import { GroupedResponses } from "../../types";
import Tags from "../Tags";

interface ResponseTextProps {
  responses: GroupedResponses[];
  techStack?: { primary: string[]; secondary: string[] };
}

const { TAG_TEXT } = QuestionType;

const ResponseText: React.FC<ResponseTextProps> = ({
  responses,
  techStack,
}) => {
  return (
    <>
      {responses.map((response, index) => {
        let res = getResponse(response);
        return response.question.type === TAG_TEXT && res !== "-" ? (
          <TagsContainer>
            {splitResponse(res).map((resp) => (
              <Tags
                disabled
                key={index}
                hasMargin
                label={resp}
                techStack={techStack}
              />
            ))}
          </TagsContainer>
        ) : (
          <Text key={index}>{res}</Text>
        );
      })}
    </>
  );
};

export default ResponseText;
