import { useState } from "react";

import { updateTagResponses } from "../pages/ResponseDetails/services/updateTagResponses";
import useTagLogic from "./useTagLogic";

interface Props {
  responseId: number;
  maturityCategory?: string;
  temperatureCategory?: string;
  techsTags?: string[];
}

const useResponseTagsLogic = ({
  responseId,
  maturityCategory,
  temperatureCategory,
  techsTags,
}: Props) => {
  const [isEditing, setIsEditing] = useState(false);
  const {
    handleTagClick: handleMaturityTagClick,
    selectedTag: selectedMaturityTag,
    setSelectedTag: setSelectedMaturityTag,
  } = useTagLogic({ initialTag: maturityCategory });
  const {
    handleTagClick: handleTemperatureTagClick,
    selectedTag: selectedTemperatureTag,
    setSelectedTag: setSelectedTemperatureTag,
  } = useTagLogic({ initialTag: temperatureCategory });
  const {
    handleTagClick: handleTechTagClick,
    selectedTag: selectedTechTags,
    setSelectedTag: setSelectedTechTags,
  } = useTagLogic({ initialTag: techsTags });

  const handleCancel = () => {
    setSelectedMaturityTag(maturityCategory?.toLowerCase());
    setSelectedTemperatureTag(temperatureCategory?.toLowerCase());
    setSelectedTechTags(techsTags?.map((tag) => tag.toLowerCase()));
    setIsEditing(false);
  };

  const handleSaveChanges = async () => {
    await updateTagResponses(responseId, {
      maturityCategory: selectedMaturityTag as string,
      temperatureCategory: selectedTemperatureTag as string,
      techsTags: selectedTechTags as string[],
    });

    setIsEditing(false);
  };

  return {
    isEditing,
    setIsEditing,
    selectedMaturityTag: selectedMaturityTag as string,
    selectedTemperatureTag: selectedTemperatureTag as string,
    selectedTechTags: selectedTechTags as string[],
    handleCancel,
    handleMaturityTagClick,
    handleTechTagClick,
    handleTemperatureTagClick,
    handleSaveChanges,
    noTagsSelected:
      !selectedMaturityTag &&
      !selectedTemperatureTag &&
      !selectedTechTags?.length,
  };
};

export default useResponseTagsLogic;
