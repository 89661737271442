import { AxiosResponse } from "axios";

import api from "../../../api";
import store from "../../../redux/store";
import {
  clearApiError,
  setApiError,
  updateUserResponseTags,
} from "redux/slices/userResponseSlice";
import { UpdateResponseTagsState } from "../../../types";

const { dispatch } = store;

export const updateTagResponses = async (
  id: number,
  body: UpdateResponseTagsState
) => {
  const url = `user-responses/${id}/tags`;
  let response;
  await api
    .put(url, body)
    .then((res: AxiosResponse) => {
      dispatch(clearApiError());
      dispatch(updateUserResponseTags(res?.data));
      response = res?.data;
    })
    .catch((error) => {
      console.log("[updateTagResponses error]: ", error);
      dispatch(setApiError({ error: true }));
    });

  return response;
};
