import { styled, Typography } from "@mui/material";
import theme from "../../definitions/theme";

interface TagProps {
  variantType: string;
  hasMargin?: boolean;
  disabled?: boolean;
}

export const StyledTag = styled("div")<TagProps>(
  ({ variantType, hasMargin, disabled }) => {
    const {
      customPalette: {
        tag: { active, hover, normal },
      },
    } = theme;

    return {
      display: "flex",
      flexDirection: "row",
      width: "fit-content",
      maxWidth: "100%",
      height: "32px",
      padding: "7px 12px 7px 12px",
      gap: "5px",
      cursor: disabled ? "auto" : "pointer",
      borderRadius: "7px",
      margin: hasMargin ? "5px 5px 5px 0px" : "0px",
      backgroundColor:
        variantType == "active" ? active.background : normal.background,
      ":hover": !disabled
        ? {
            boxShadow: hover.boxShadow,
          }
        : undefined,
      "-webkit-tap-highlight-color": "transparent",
    };
  }
);

export const TagText = styled(Typography)(() => {
  const {
    customSizes: {
      tag: { text },
    },
    customPalette: {
      tag: { normal },
    },
  } = theme;

  return {
    fontSize: text.fontSize,
    fontWeight: text.fontWeight,
    lineHeight: text.lineHeight,
    color: normal.text,
    overflow: "hidden",
  };
});
