import ELK, { ElkNode } from "elkjs/lib/elk.bundled.js";
import { useCallback } from "react";
import { useReactFlow } from "reactflow";

import {
  CheckBox,
  DatePicker,
  DesignIcon,
  Dropdown,
  DropdownArrow,
  LongText,
  QuantitySelector,
  Radio,
  ResponsesIcon,
  SettingsIcon,
  ShortText,
  SurveyIcon,
  Tag,
} from "../assets";
import {
  LINKS,
  PRIMARY_BUTTON,
  SECONDARY_BUTTON,
  TAGS,
  TEXT_COLOR,
  TITLE_COLOR,
  BACKGROUND,
  HOME_PAGE_TEXT_COLOR,
} from "./constants";
import theme from "../definitions/theme";
import {
  BrandingState,
  QuestionGroupState,
  GroupedQuestionResponses,
  GroupedResponses,
  AdminResponsesState,
} from "../types";

export const sidebarOptions = [
  {
    Icon: SurveyIcon,
    editStroke: true,
    editRect: true,
    text: "Survey",
    key: "survey-icon",
    path: "/survey",
  },
  {
    Icon: ResponsesIcon,
    editStroke: false,
    editRect: true,
    text: "Responses",
    key: "responses-icon",
    path: "/responses",
  },
  {
    Icon: DesignIcon,
    editStroke: true,
    editRect: true,
    text: "Design",
    key: "design-icon",
    path: "/design",
    subSections: ["Styling", "Branding"],
  },
  {
    Icon: SettingsIcon,
    editStroke: false,
    editRect: true,
    text: "Settings",
    key: "settings-icon",
    path: "/settings",
  },
];

export interface ColorPaletteOptions {
  selectedColor: string;
  defaultColor: string;
  label: string;
  className: string;
  buttonClassname: string;
}

export const colorPaletteOptions = (branding: BrandingState) => [
  {
    defaultColor: theme.customPalette.secondary,
    selectedColor: branding.primaryColor,
    label: PRIMARY_BUTTON,
    className: "PrimaryColorContainer",
    buttonClassname: "PrimaryColorButton",
  },
  {
    defaultColor: theme.customPalette.hover,
    selectedColor: branding.secondaryColor,
    label: SECONDARY_BUTTON,
    className: "SecondaryColorContainer",
    buttonClassname: "SecondaryColorButton",
  },
  {
    defaultColor: theme.customPalette.tagsActive,
    selectedColor: branding.tagColor,
    label: TAGS,
    className: "TagsColorContainer",
    buttonClassname: "TagsColorButton",
  },
  {
    defaultColor: theme.customPalette.secondary,
    selectedColor: branding.linkColor,
    label: LINKS,
    className: "LinksColorContainer",
    buttonClassname: "LinksColorButton",
  },
  {
    defaultColor: theme.customPalette.primary,
    selectedColor: branding.backgroundColor,
    label: BACKGROUND,
    className: "BackgroundColorContainer",
    buttonClassname: "BackgroundColorButton",
  },
];

export const typographyOptions = (branding: BrandingState) => [
  {
    defaultColor: theme.customPalette.secondary,
    selectedColor: branding.primaryTextColor,
    label: TITLE_COLOR,
    className: "TitleColorContainer",
    buttonClassname: "TitleColorButton",
  },
  {
    defaultColor: theme.customPalette.hover,
    selectedColor: branding.secondaryTextColor,
    label: TEXT_COLOR,
    className: "TextColorContainer",
    buttonClassname: "TextColorButton",
  },
  {
    defaultColor: theme.customPalette.white,
    selectedColor: branding.homePageTextColor,
    label: HOME_PAGE_TEXT_COLOR,
    className: "HomePageTextColorContainer",
    buttonClassname: "HomePageTextColorButton",
  },
];

export const tagsOptions = [
  {
    id: 1,
    optionText: "Type of user 1",
  },
  {
    id: 2,
    optionText: "Type of user 2",
  },
];

export const radioButtonOptions = [
  {
    id: 1,
    checked: true,
    optionText: "Less than $10K",
  },
  {
    id: 2,
    checked: false,
    optionText: "From $10K and less than $25K",
  },
  {
    id: 3,
    checked: false,
    optionText: "From $25K and less than $50K",
  },
  {
    id: 4,
    checked: false,
    optionText: "From $50K and less than $100K",
  },
  {
    id: 5,
    checked: false,
    optionText: "$100K or more",
  },
];

export enum QuestionType {
  LONG_TEXT = "long-text",
  SHORT_TEXT = "short-text",
  TAG_TEXT = "tag-text",
  CHECKBOX = "checkbox",
  RADIO = "radio",
  NUMERIC_INPUT = "numeric-input",
  DROPDOWN = "dropdown",
  DATE = "date",
  DROPDOWN_INPUT = "dropdown-input",
  BIFURCATE = "bifurcate",
}

export const mapToQuestionType = (type: string[]) => {
  const map = {
    [QuestionType.LONG_TEXT]: "long-text",
    [QuestionType.SHORT_TEXT]: "short-text",
    [QuestionType.TAG_TEXT]: "tag-text",
    [QuestionType.CHECKBOX]: "checkbox",
    [QuestionType.RADIO]: "radio",
    [QuestionType.NUMERIC_INPUT]: "numeric-input",
    [QuestionType.DROPDOWN]: "dropdown",
    [QuestionType.DATE]: "date",
    [QuestionType.DROPDOWN_INPUT]: "dropdown-input",
    [QuestionType.BIFURCATE]: "bifurcate",
  };

  return type.map((t) => map[t]);
};

export const getQuestionTypeData = (type: QuestionType) => {
  switch (type) {
    case "long-text":
      return {
        title: "Long Text",
        Icon: LongText,
      };
    case "short-text":
      return { title: "Short Text", Icon: ShortText };
    case "tag-text":
      return { title: "Tag Text", Icon: Tag };
    case "checkbox":
      return { title: "Checkbox", Icon: CheckBox };
    case "radio":
      return { title: "Multiple choice", Icon: Radio };
    case "numeric-input":
      return { title: "Numeric Input", Icon: QuantitySelector };
    case "dropdown":
      return { title: "Dropdown", Icon: DropdownArrow };
    case "date":
      return { title: "Calendar", Icon: DatePicker };
    case "dropdown-input":
      return { title: "Choose & Type", Icon: Dropdown };
    default:
      return { title: "Long Text", Icon: LongText };
  }
};

export const validateFile = (file: File, key: string): Promise<boolean> => {
  let validHeight = 0;
  let validWidth = 0;

  switch (key) {
    case "logo":
      validHeight = 36;
      validWidth = 180;
      break;
    case "signature":
      validHeight = 200;
      validWidth = 667;
      break;
    case "successIcon":
      validHeight = 370;
      validWidth = 370;
      break;
    case "emailIcon":
      validHeight = 149;
      validWidth = 149;
      break;
  }

  return new Promise((resolve) => {
    const image = new Image();
    const objectUrl = URL.createObjectURL(file);
    image.onload = function () {
      const element = this as HTMLImageElement;
      const isValid =
        element.width <= validWidth && element.height <= validHeight;
      URL.revokeObjectURL(objectUrl);
      resolve(isValid);
    };
    image.onerror = function () {
      URL.revokeObjectURL(objectUrl);
      resolve(false);
    };
    image.src = objectUrl;
  });
};

export const getFileNameFromUrl = (url: string) => {
  if (url) {
    const filenameWithExtension = url.substring(url.lastIndexOf("/") + 1);

    const filenameWithoutExtension = filenameWithExtension
      .split("_")[1]
      .split(".")[0];

    return filenameWithoutExtension;
  }
  return null;
};

export const hexToRgb = (hex: string) => {
  var bigint = parseInt(hex.substring(1), 16);
  var r = (bigint >> 16) & 255;
  var g = (bigint >> 8) & 255;
  var b = bigint & 255;
  return [r, g, b];
};

export const getBrightness = (r: number, g: number, b: number) =>
  (r * 299 + g * 587 + b * 114) / 1000;

export const getTextColor = (hexColor: string) => {
  var rgb = hexToRgb(hexColor);
  var brightness = getBrightness(rgb[0], rgb[1], rgb[2]);
  var textColor =
    brightness > 125
      ? theme.customPalette.darkMediumGrey
      : theme.customPalette.white;

  return textColor;
};

const elk = new ELK();

interface CustomNode {
  id: string;
  type: string;
  data: { label: string };
  position: { x: number; y: number };
  width: number;
  height: number;
}

export const useLayoutedElements = () => {
  const { getNodes, setNodes, getEdges, fitView } = useReactFlow();
  const defaultOptions = {
    "elk.algorithm": "layered",
    "elk.layered.spacing.nodeNodeBetweenLayers": 100,
    "elk.spacing.nodeNode": 80,
  };

  const getLayoutedElements = useCallback((options) => {
    const customNodes: CustomNode[] = getNodes().map((node) => ({
      id: node.id,
      type: node.type,
      data: node.data,
      position: node.position,
      width: node.width,
      height: node.height,
    }));

    const layoutOptions = { ...defaultOptions, ...options };
    const graph = {
      id: "root",
      layoutOptions: layoutOptions,
      children: customNodes,
      edges: getEdges().map((edge) => ({
        id: edge.id,
        sources: [edge.source],
        targets: [edge.target],
        data: edge.data,
      })),
    };

    elk.layout(graph).then(({ children }) => {
      // By mutating the children in-place we save ourselves from creating a
      // needless copy of the nodes array.
      children.forEach((child: ElkNode, index: number) => {
        customNodes[index].position = {
          x: child.x,
          y: child.y,
        };
      });

      setNodes(customNodes);
      window.requestAnimationFrame(() => {
        fitView();
      });
    });
  }, []);

  return { getLayoutedElements };
};

export const groupQuestionResponses = (
  questionResponses: GroupedQuestionResponses[]
) => {
  const groupedByGroupId: GroupedQuestionResponses[][] = [];

  questionResponses.forEach((groupedQuestionResponse) => {
    const groupId = groupedQuestionResponse.questionGroupId;

    if (!groupedByGroupId[groupId]) {
      groupedByGroupId[groupId] = [];
    }

    groupedByGroupId[groupId].push(groupedQuestionResponse);
  });

  groupedByGroupId.forEach((group) => {
    group.sort((a, b) => a.question.index - b.question.index);
  });
  return groupedByGroupId;
};

const {
  BIFURCATE,
  CHECKBOX,
  RADIO,
  NUMERIC_INPUT,
  TAG_TEXT,
  DROPDOWN,
  DROPDOWN_INPUT,
  SHORT_TEXT,
  LONG_TEXT,
  DATE,
} = QuestionType;

export const isAdminAnswer = (responses: GroupedResponses[]) => {
  const type = responses[0].question.type;

  if (
    type === SHORT_TEXT ||
    type === LONG_TEXT ||
    type === DATE ||
    type === TAG_TEXT
  )
    return !responses[0].text && !!responses[0].adminText;

  if (type === BIFURCATE)
    return (
      !responses[0].clientQuestionOption && !!responses[0].adminQuestionOptionId
    );

  if (type === DROPDOWN || type === RADIO)
    return (
      !responses[0].clientQuestionOptionId &&
      !!responses[0].adminQuestionOptionId
    );

  if (type === DROPDOWN_INPUT) {
    return (
      !responses[0].clientQuestionOptionId &&
      !responses[0].text &&
      !!responses[0].adminQuestionOption &&
      !!responses[0].adminText
    );
  }

  if (type === CHECKBOX || type === NUMERIC_INPUT)
    return (
      !responses.some((res) => res.clientQuestionOptionId) &&
      responses.some((res) => res.adminQuestionOption)
    );
};

export const isEditable = (responses: GroupedResponses[]) => {
  const type = responses[0].question.type;

  if (
    type === SHORT_TEXT ||
    type === LONG_TEXT ||
    type === DATE ||
    type === TAG_TEXT
  )
    return !responses[0].text;

  if (type === BIFURCATE)
    return (
      !responses[0].clientQuestionOption && !responses[0].adminQuestionOptionId
    );

  if (type === DROPDOWN || type === RADIO)
    return !responses[0].clientQuestionOptionId;

  if (type === DROPDOWN_INPUT) {
    return !responses[0].clientQuestionOptionId && !responses[0].text;
  }

  if (type === CHECKBOX || type === NUMERIC_INPUT)
    return !responses.some((res) => res.clientQuestionOptionId);
};

export const getResponse = (response: GroupedResponses) => {
  const type = response.question.type;

  if (
    type === SHORT_TEXT ||
    type === LONG_TEXT ||
    type === DATE ||
    type === TAG_TEXT
  )
    return response.text || response.adminText || "-";

  if (
    type === DROPDOWN ||
    type === BIFURCATE ||
    type === RADIO ||
    type === CHECKBOX
  ) {
    return (
      response.clientQuestionOption?.optionText ||
      response.adminQuestionOption?.optionText ||
      "-"
    );
  }

  if (type === NUMERIC_INPUT || type === DROPDOWN_INPUT) {
    if (response.clientQuestionOption)
      return response.clientQuestionOption.optionText + " - " + response.text;
    else if (response.adminQuestionOption)
      return (
        response.adminQuestionOption.optionText + " - " + response.adminText
      );
    else return "-";
  }
};

export const parseResponses = (responses: GroupedResponses[]) => {
  return responses
    .map((response) => ({
      id: response.id,
      adminText: response.adminText,
      adminQuestionOptionId: response.adminQuestionOptionId,
    }))
    .filter(
      (response) =>
        response.adminText !== null || response.adminQuestionOptionId !== null
    );
};

export const convertGroupedToAdminResponses = (
  groupedResponses: GroupedQuestionResponses[][]
): AdminResponsesState[] => {
  return groupedResponses.flatMap((group) =>
    group.map((groupedQuestionResponse) => {
      const filteredResponses = parseResponses(
        groupedQuestionResponse.questionResponses
      );

      return {
        questionId: groupedQuestionResponse.questionId,
        questionType: groupedQuestionResponse.question.type,
        questionResponses:
          filteredResponses.length > 0 ? filteredResponses : [],
      };
    })
  );
};

export const isTarget = (questionGroups: QuestionGroupState[], id: number) => {
  return questionGroups
    .flatMap((questionGroup) => questionGroup?.pathRule || [])
    .some((pathRule) => pathRule?.nextGroup?.id === id);
};

export const splitResponse = (resp: string) =>
  resp?.replace(/ /g, "").split(",");

export const getTagColor = (tag: string) => {
  const normalizedTag = tag.trim().toLowerCase();
  switch (normalizedTag) {
    case "mature":
      return theme.customPalette.categorizationTags.mature;
    case "barely mature":
      return theme.customPalette.categorizationTags.barelyMature;
    case "immature":
      return theme.customPalette.categorizationTags.immature;
    case "cold":
      return theme.customPalette.categorizationTags.cold;
    case "warm":
      return theme.customPalette.categorizationTags.warm;
    case "hot":
      return theme.customPalette.categorizationTags.hot;
    case "frontend":
      return theme.customPalette.categorizationTags.frontend;
    case "backend":
      return theme.customPalette.categorizationTags.backend;
    case "web":
      return theme.customPalette.categorizationTags.web;
    case "mobile":
      return theme.customPalette.categorizationTags.mobile;
    default:
      return "";
  }
};

export const getTagTextColor = (tag: string) => {
  const normalizedTag = tag.trim().toLowerCase();
  if (
    normalizedTag === "immature" ||
    normalizedTag === "frontend" ||
    normalizedTag === "warm"
  )
    return theme.customPalette.darkMediumGrey;
  return theme.customPalette.white;
};

export const filterTagsOptions = {
  0: "Cold",
  1: "Warm",
  2: "Hot",
  3: "Immature",
  4: "Barely Mature",
  5: "Mature",
  6: "Frontend",
  7: "Backend",
  8: "Web",
  9: "Mobile",
};

export const temperatureTagsOptions = ["cold", "warm", "hot"];

export const maturityTagsOptions = ["immature", "barely mature", "mature"];

export const techTagsOptions = ["frontend", "backend", "web", "mobile"];
