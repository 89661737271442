import styled from "styled-components";

export const Container = styled("div")({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  height: "100vh",
  padding: "40px 84px 40px 84px",
});

export const TextContainer = styled("div")({
  display: "flex",
  flexDirection: "row",
  width: "100%",
  alignItems: "center",
  gap: "16px",
  marginBottom: "16px",
});

export const BarContainer = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "flex-start",
  marginBottom: "32px",
  gap: "32px",
});

export const LeftContainer = styled("div")(({ theme }) => ({
  display: "flex",
  gap: "12px",
  width: "80%",

  [theme.breakpoints.down(1340)]: {
    gap: "16px",
    flexWrap: "wrap",
    
    "& > *": {
      flexBasis: "calc(50% - 16px)",
      boxSizing: "border-box",
    },
  },
}));

export const ButtonContainer = styled("div")({
  justifyContent: "flex-end",
});

export const LeftRowContainer = styled("div")({
  display: "flex",
  flexDirection: "row",
  gap: "48px",
  maxWidth: "100%",
});

export const Title = styled("h1")(({ theme }) => {
  const {
    customSizes: {
      heading: {
        h6: { fontSize, fontWeight, lineHeight },
      },
    },
    customPalette: { blackGrey },
    customFonts: { poppinsSemiBold },
  } = theme;

  return {
    fontSize,
    fontWeight,
    lineHeight,
    color: blackGrey,
    fontFamily: poppinsSemiBold,
  };
});

export const SubTitle = styled("h1")(({ theme }) => {
  const {
    customSizes: {
      textAreaInput: {
        desktop: {
          placeholder: { fontSize, fontWeight, lineHeight },
        },
      },
    },
    customPalette: { grey },
    customFonts: { poppins },
  } = theme;

  return {
    fontSize,
    fontWeight,
    lineHeight,
    color: grey,
    fontFamily: poppins,
  };
});

export const TableHead = styled("div")(({ theme }) => {
  const {
    customPalette: {
      card: { boxShadow },
    },
  } = theme;

  return {
    borderRadius: "8px",
    height: "56px",
    boxShadow,
    marginBottom: "8px",
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    gap: "48px",
    padding: "0 32px 0 32px",
  };
});

export const TableRow = styled("div")(({ theme }) => {
  const {
    customPalette: {
      card: { boxShadow },
    },
  } = theme;
  return {
    display: "flex",
    borderRadius: "15px",
    height: "80px",
    boxShadow,
    padding: "0 32px 0 32px",
    cursor: "pointer",
    alignItems: "center",
    marginBottom: "16px",
    "-webkit-tap-highlight-color": "transparent",
  };
});

export const TableCell = styled("div")(({ theme }) => {
  const {
    customSizes: {
      body: {
        smallDescription: {
          regular: { fontSize, fontWeight, lineHeight },
        },
      },
    },

    customFonts: { poppinsSemiBold, poppins },
  } = theme;

  return {
    display: "flex",
    alignItems: "center",
    width: "190px",
    height: "72px",
    fontFamily: poppins,
    fontSize,
    fontWeight,
    lineHeight,
    justifyContent: "flex-start",

    "&.bold": {
      fontFamily: poppinsSemiBold,
    },
  };
});

export const Text = styled("div")<{ color?: string }>(({ theme, color }) => {
  const {
    customSizes: {
      body: {
        smallDescription: {
          regular: { fontSize, fontWeight, lineHeight },
        },
      },
    },
    customFonts: { poppins, poppinsSemiBold },
  } = theme;

  return {
    fontFamily: poppins,
    fontSize,
    fontWeight,
    lineHeight,
    color,
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
    "&.bold": {
      fontFamily: poppinsSemiBold,
    },
  };
});

export const IconButton = styled("div")(({ theme }) => {
  const {
    customPalette: { secondary },
  } = theme;

  return {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginLeft: "15px",
    cursor: "pointer",
    height: "35px",
    width: "35px",
    borderRadius: "5px",
    ":hover": {
      path: {
        fill: secondary,
        stroke: secondary,
      },
    },
  };
});

export const ButtonsCell = styled("div")({
  display: "flex",
  flexGrow: 2,
  justifyContent: "flex-end",
  alignItems: "center",
});

export const TagsContainer = styled("div")({
  display: "flex",
  alignItems: "center",
  maxWidth: "180px",
});
