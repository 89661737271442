import React from "react";
import Modal from "@mui/material/Modal";

import Button from "../button";
import { DarkCross } from "../../assets/index";
import {
  ModalContainer,
  TopContainer,
  Label,
  TitleLabel,
  ButtonContainer,
  CrossButton,
  TextContainer,
  Icon,
  IconsContainer,
} from "./styles";
import { TRY_AGAIN, OPS, ERROR_MODAL } from "../../utils/constants";

const ErrorModal = ({ isOpen, handleClose, handleTryAgain = null }) => (
  <Modal
    open={isOpen}
    aria-labelledby="modal-modal-title"
    aria-describedby="modal-modal-description"
  >
    <ModalContainer>
      <TopContainer>
        <IconsContainer>
          <Icon />
          <CrossButton onClick={handleClose}>
            <DarkCross />
          </CrossButton>
        </IconsContainer>
        <TextContainer>
          <TitleLabel>{OPS}</TitleLabel>
          <Label>{ERROR_MODAL}</Label>
        </TextContainer>
      </TopContainer>
      {handleTryAgain && <ButtonContainer>
        <Button
          label={TRY_AGAIN}
          variantType="primary"
          width={187}
          height={48}
          onClick={handleTryAgain}
        />
      </ButtonContainer>}
    </ModalContainer>
  </Modal>
);

export default ErrorModal;
