import styled from "styled-components";
import {
  Divider,
  List,
  ListItem,
  ListItemButton,
  Toolbar,
  ListItemIcon,
} from "@mui/material";

import { Logo } from "../../assets";

export const Container = styled("div")(({ theme }) => {
  const {
    customPalette: { secondary },
  } = theme;
  return {
    display: "flex",
    flexDirection: "column",
    height: "100vh",
    width: "100%",
    maxWidth: "200px",
    backgroundColor: secondary,

    [theme.breakpoints.down(768)]: {
      minHeight: "max-content",
    },
  };
});

export const SidebarLogo = styled(Logo as any)(() => {
  return {
    width: "125px",
    height: "60px",
  };
});

export const Item = styled(ListItem)<{
  subSection?: boolean;
}>(({ theme, subSection }) => {
  const {
    customPalette: { white, secondary },
  } = theme;
  return {
    "&.MuiListItem-root": {
      justifyContent: "center",
      marginBottom: "16px",
      borderRadius: "4px",
      height: "45px",
    },
    "&& .Mui-selected, && .Mui-selected:hover": {
      borderRadius: "4px",
      backgroundColor: subSection ? secondary : white,
      height: "45px",
    },
  };
});

export const ItemIcon = styled(ListItemIcon)(({ theme }) => {
  return {
    "&.MuiListItemIcon-root": {
      minWidth: "24px",
      marginRight: "8px",
    },
  };
});

export const ItemButton = styled(ListItemButton)<{
  editStroke?: boolean;
  editRect?: boolean;
}>(({ theme, editRect, editStroke }) => {
  const {
    customPalette: { secondary, white },
  } = theme;

  return {
    "&.MuiListItemButton-root.Mui-selected": {
      justifyContent: "center",
      margin: "0px 16px",
      padding: 0,

      "& .MuiListItemIcon-root": {
        path: {
          fill: !editStroke ? secondary : "",
          stroke: editStroke ? secondary : "",
        },
        rect: {
          fill: white,
          stroke: editRect ? secondary : "",
        },
      },
    },
    "&.MuiListItemButton-root": {
      justifyContent: "center",
      margin: "0px 16px",
      padding: 0,

      alignSelf: "center",
      borderRadius: "4px",
      height: "45px",
    },
    "&.MuiListItemButton-root:hover": {
      justifyContent: "center",
      padding: 0,
      backgroundColor: white,
      color: secondary,
      borderRadius: "4px",
      height: "45px",
      ":hover": {
        "& h1": {
          color: secondary,
        },
      },

      "& .MuiListItemIcon-root": {
        path: {
          fill: !editStroke ? secondary : "",
          stroke: editStroke ? secondary : "",
        },
        rect: {
          fill: white,
          stroke: editRect ? secondary : "",
        },
      },
    },
  };
});

export const Label = styled("h1")<{ selected?: boolean }>(
  ({ theme, selected }) => {
    const {
      customSizes: {
        textField: {
          label: { fontSize, lineHeight },
        },
      },
      customPalette: { white, secondary },
      customFonts: { poppins },
    } = theme;

    return {
      fontSize: fontSize,
      fontWeight: 600,
      lineHeight: lineHeight,
      color: selected ? secondary : white,
      fontFamily: poppins,
    };
  }
);

export const LogoToolbar = styled(Toolbar)(() => {
  return {
    "&.MuiToolbar-root": {
      marginBottom: "48px",
      marginTop: "32px",
      alignSelf: "center",
    },
  };
});

export const BottomDivider = styled(Divider)(({ theme }) => {
  const {
    customPalette: { white },
  } = theme;
  return {
    "&.MuiDivider-root": {
      borderColor: white,
      marginBottom: "32px",

      [theme.breakpoints.down(768)]: {
        marginTop: "40vh",
      },
    },
  };
});

export const ButtonContainer = styled("div")(() => {
  return {
    display: "flex",
    alignItems: "center",
    width: "100%",
    paddingLeft: "8px",
  };
});

export const ListStyled = styled(List)(({}) => {
  return {
    width: "100%",
    height: "100vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  };
});

export const ItemsContainer = styled("div")(({}) => {
  return {
    display: "flex",
    flexDirection: "column",
  };
});

export const BottomContainer = styled("div")(({}) => {
  return {
    display: "flex",
    flexDirection: "column",
  };
});
